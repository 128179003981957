import React, { createContext, useState, useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch to dispatch actions
import { URL } from '../utils/apiURL';
import { setAuth, setUser } from '../utils/slices/authSlice';

const { LOGIN_IN_URL } = URL;

// Create AuthContext
const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [user, setUserState] = useState(null);
  const dispatch = useDispatch(); // Initialize dispatch

  const login = async (email, password) => {
    try {
      const response = await fetch(LOGIN_IN_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok && response.status === 200) {
        setUserState(data.data); // Set local user state
        localStorage.setItem('token', data.data.authToken); // Store token in localStorage

        // Dispatch setAuth and setUser to update Redux state
        dispatch(setAuth(true)); // User is authenticated
        dispatch(setUser(data.data.userData)); // Set the user info in Redux

        return {
          success: true,
          ...data
        };
      }
      return {
        success: false,
        ...data
      };
    } catch (error) {
      console.error('Login failed', error);
      return { success: false, message: 'Login failed due to an error' };
    }
  };

  const logout = useCallback(() => {
    setUserState(null); // Clear local user state
    localStorage.removeItem('token');

    // Dispatch setAuth and clear the user in Redux
    dispatch(setAuth(false)); // User is not authenticated
    dispatch(setUser(null)); // Clear the user info in Redux
  }, [dispatch]);

  const setVerifiedUser = useCallback((user) => {
    setUserState(user); // Set local user state

    // Dispatch setUser to update Redux state
    dispatch(setUser(user)); 
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{ user, login, logout, setVerifiedUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use AuthContext
export const useAuth = () => useContext(AuthContext);
